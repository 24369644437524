import { IconButton } from '@synoptic/ui-kit/button/icon-button.tsx';
import { ArrowLeftIcon } from '@synoptic/ui-kit/icons/react/arrow-left.tsx';
import { useLocation, useNavigate } from 'react-router';
import { FC } from 'react';
import { headerBack } from '@/components/page-header/back-button.css.ts';

export const BackButton: FC<{ fallbackReturnTo?: string }> = ({
  fallbackReturnTo,
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <IconButton
      className={headerBack}
      onClick={() => {
        if (history.state.idx > 0 && !state?.isNavigatingUp) {
          navigate(-1);
        } else {
          navigate(fallbackReturnTo || '..', {
            state: { isNavigatingUp: true },
          });
        }
      }}
      variant="tertiary"
      size="small"
    >
      <ArrowLeftIcon style={{ width: 24, height: 24 }} />
    </IconButton>
  );
};
